import React, {ReactNode, useRef, useState, useLayoutEffect} from "react";
import classes from "classnames";

import Stack from "../primitives/Stack";

type ModalContentProps = {
    "children": ReactNode;
    "data-cy"?: string;
    "paddingLeft"?: "medium" | "large";
    "paddingRight"?: "medium" | "large";
};

export default function ModalContent({
    children,
    "data-cy": dataCy,
    paddingLeft = "large",
    paddingRight = "large",
}: ModalContentProps) {
    const ref = useRef<HTMLDivElement>(null);
    const [isOverflown, setIsOverflown] = useState(false);
    const [isScrollTop, setIsScrollTop] = useState(false);
    const [isScrollBottom, setIsScrollBottom] = useState(false);

    useLayoutEffect(() => {
        const contentNode = ref.current;
        if (contentNode) setIsOverflown(contentNode.clientHeight < contentNode.scrollHeight);
    });

    const handleScroll = (event: any) => {
        const {scrollHeight, scrollTop, clientHeight} = event.target;
        setIsScrollTop(Math.abs(scrollTop) <= 1);
        setIsScrollBottom(Math.abs(scrollHeight - (scrollTop + clientHeight)) <= 1);
    };

    return (
        <Stack
            className={classes(
                "ng-modal-content",
                `ng-padding-left-${paddingLeft}`,
                `ng-padding-right-${paddingRight}`,
                {"ng-is-overflown": isOverflown},
                {"ng-is-scroll-top": isScrollTop},
                {"ng-is-scroll-bottom": isScrollBottom},
            )}
            data-cy={dataCy}
            gap="small"
            onScroll={handleScroll}
            ref={ref}
        >
            {children}
        </Stack>
    );
}
