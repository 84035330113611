import React, {ReactNode} from "react";
import {Dialog} from "@headlessui/react";
import classes from "classnames";
import {Lato} from "next/font/google";

import ModalCloseButton from "./ModalCloseButton";

const lato = Lato({
    weight: ["300", "400", "700"],
    subsets: ["latin"],
    variable: "--lato",
});

interface ModalProps {
    "children": ReactNode;
    "data-cy"?: string;
    "size"?: "standard" | "large";
    "open": boolean;
    "onClose": () => any;
}

export default function Modal({size = "standard", open, onClose, children, "data-cy": dataCy}: ModalProps) {
    return (
        <Dialog open={open} onClose={onClose} className={classes(lato.className, "ng-modal")} data-cy={dataCy}>
            <div data-cy="modal-backdrop" className="ng-modal-backdrop" aria-hidden="true" />
            <div data-cy="modal-container" className="ng-modal-container">
                <Dialog.Panel data-cy="modal-panel" className={classes("ng-modal-panel", `ng-size-${size}`)}>
                    <ModalCloseButton onClose={onClose} />
                    {children}
                </Dialog.Panel>
            </div>
        </Dialog>
    );
}
