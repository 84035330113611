"use client";

import React, {useState, useEffect, ReactNode} from "react";
import {NewsletterModal} from "@shared/types/payload-types";
import {TrackingData} from "@shared/types/editorial-types";

import {store} from "@business/components/common/Storage";
import {tracker} from "@business/components/common/Tracker";
import useUserStore from "@business/components/common/UserModel";
import Modal from "@ng-components/modal/Modal";
import Suite from "@ng-components/primitives/Suite";
import Stack from "@ng-components/primitives/Stack";
import Title from "@ng-components/primitives/Title";
import Paragraph from "@ng-components/primitives/Paragraph";
import ModalContent from "@ng-components/modal/ModalContent";
import NewsletterForm from "@ng-components/form/block/NewsletterForm";

const NEWSLETTER_MODAL_DELAY_IN_SECONDS = 10;

type NewsletterModalInnerProps = {
    coupon?: string | null;
    delayInSeconds?: number;
    description: string;
    image: ReactNode;
    internalTitle: string;
    notifications: NewsletterModal["notifications"];
    reference: string;
    submitButtonText?: string;
    title: string;
};

export default function NewsletterModalInner({
    coupon,
    delayInSeconds = NEWSLETTER_MODAL_DELAY_IN_SECONDS,
    description,
    image,
    internalTitle,
    notifications,
    reference,
    submitButtonText = "Envoyer",
    title,
}: NewsletterModalInnerProps) {
    const [showNewsletterModal, setShowNewsletterModal] = useState<boolean>(false);
    const userStore = useUserStore();

    const trackingData: TrackingData = {
        container: {type: "newsletter-modal", reference, internalTitle, title},
    };

    useEffect(() => {
        if (userStore.isSynced() && !userStore.isAuthenticated()) {
            setTimeout(() => {
                setShowNewsletterModal(store.get("newsletter-modal") !== "hidden");
            }, delayInSeconds * 1000);
        }
    }, [userStore]);

    useEffect(() => {
        if (showNewsletterModal === true) tracker.viewModal({container: trackingData.container});
    }, [showNewsletterModal]);

    const handleClose = () => {
        store.set("newsletter-modal", "hidden");
        setShowNewsletterModal(false);
    };

    return (
        <Modal size="large" open={showNewsletterModal} onClose={handleClose}>
            <ModalContent paddingLeft="medium" paddingRight="medium">
                <Suite className="ng-newsletter-modal-columns" verticalAlign="top" wrap="mobile">
                    <Stack className="ng-column-form">
                        <Title level={2} size="medium">
                            {title}
                        </Title>
                        <Paragraph>{description}</Paragraph>
                        <NewsletterForm
                            coupon={coupon}
                            formTracker="newsletter-modal"
                            notifications={notifications}
                            onSubmit={handleClose}
                            source="modal"
                            submitButtonFullWidth={true}
                            submitButtonJustify="left"
                            submitButtonText={submitButtonText}
                            trackingData={trackingData}
                        />
                    </Stack>
                    <div className="ng-column-image">{image}</div>
                </Suite>
            </ModalContent>
        </Modal>
    );
}
