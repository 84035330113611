import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark as closeIcon} from "@fortawesome/free-solid-svg-icons";

interface ModalCloseButtonProps {
    onClose: () => any;
}

export default function ModalCloseButton({onClose}: ModalCloseButtonProps) {
    return (
        <button
            data-cy="modal-close-button"
            className="ng-modal-close-button"
            aria-label="close"
            onClick={onClose}
            autoFocus
        >
            <FontAwesomeIcon icon={closeIcon} fixedWidth />
        </button>
    );
}
