class Storage {
    constructor() {
        if (typeof window !== "undefined") {
            this.storage = this._getBestAvailableStorage();
        }
    }

    _getBestAvailableStorage() {
        if (this._isStorageAvailable(() => localStorage)) return localStorage;
        if (this._isStorageAvailable(() => sessionStorage)) return sessionStorage;
        return null;
    }

    _isStorageAvailable(getStorage) {
        try {
            const key = "___storage_availability_test_key__";
            getStorage().setItem(key, key);
            getStorage().removeItem(key);
            return true;
        } catch {
            return false;
        }
    }

    set(key, value) {
        this.memorize(key, value);
    }

    get(key) {
        return this.getMemory(key);
    }

    remove(key) {
        if (!this.storage) return;
        this.storage?.removeItem(this.toAppKey(key));
    }

    getMemory(key) {
        if (!this.storage) return;
        let memory;
        let stored = this.storage?.getItem(this.toAppKey(key));
        if (stored) {
            stored = JSON.parse(stored);
            if (stored.memory && stored.storedAt === new Date().toLocaleDateString()) memory = stored.memory;
        }
        return memory;
    }

    memorize(key, value) {
        if (!this.storage) return;
        this.storage?.setItem(
            this.toAppKey(key),
            JSON.stringify({storedAt: new Date().toLocaleDateString(), memory: value}),
        );
    }

    toAppKey(key) {
        return `app:${key}`;
    }
}

export default Storage;

export const store = new Storage();
